class Gpt {
  constructor() {
    window.slots = {};
    // window.googletag = googletag || {};
    // window.googletag.cmd = googletag.cmd || [];

    document.addEventListener('turbo:before-visit', () => this.clearAds());
    document.addEventListener('turbo:load', () => this.evaluate());
    this.evaluate();
  }

  evaluate() {
    for (let slot of Array.from(document.querySelectorAll('.gpt-ad'))) {
      const cachedSlot = window.slots[slot.dataset.gptId];
      if (cachedSlot != null) { 
        this.refreshSlot(cachedSlot); 
      } else { 
        this.defineSlot(slot); 
        //this.setSlot(thisSlot); 
      }
    }
    window.googletag.cmd.push(function() {
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();  
    }); 
  }
  
  defineSlot(slot) {
    const divId = slot.dataset.gptId;
    const path = slot.dataset.gptPath;
    const width = parseInt(slot.dataset.gptWidth);
    const height = parseInt(slot.dataset.gptHeight);
    //const dimensions = [250, 250]   // slot.dataset.gptDimensions;
    var addedSlot
    console.log('defineSlot', slot);
    window.googletag.cmd.push(function() {      
      addedSlot = googletag.defineSlot(path, [width, height], divId).addService(googletag.pubads());
      googletag.enableServices();  
      googletag.display(divId)
      window.slots[divId] = addedSlot;
    });
  }

  setSlot(slot){
    console.log('setSlot', slot);
    window.googletag.cmd.push(function() { googletag.display(slot); });
  }

  refreshSlot(slot) {
    console.log('refreshSlot', slot);
    window.googletag.cmd.push(function() { googletag.pubads().refresh([slot]); });
  }

  clearAds() {
    console.log('clearAds');
    window.googletag.cmd.push(function() { googletag.pubads().clear();});
  }
};

this.gpt || (this.gpt = new Gpt());
